<template>
  <div>
    <h5 class="font-weight-bolder my-10">Modify Attendance</h5>
    <b-form class="mt-5">
      <b-form-group label="Status:" label-cols-lg="2">
        <b-form-select
          v-model="form.statusId"
          :options="statusList"
        ></b-form-select>
      </b-form-group>
      <b-form-group label="Remark:" label-cols-lg="2">
        <b-form-textarea v-model="form.remark"></b-form-textarea>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import swal from "sweetalert";

export default {
  name: "EmployeeAttendanceManageForm",
  data: function () {
    return {
      form: {
        statusId: "",
        remark: "",
      },
      statusList: [
        { value: "1", text: "Present" },
        { value: "2", text: "Absent" },
      ],
    };
  },
  mounted() {
    this.form.statusId =
      this.employee.att_status !== null ? this.employee.att_status.id : 2;
  },
  methods: {
    save() {
      console.log("Employee Attendance Status Modification");
      const request = {
        employee_id: this.employee.id,
        att_status_id: this.form.statusId,
        date: this.attDate,
        remark: this.form.remark,
      };

      this.$store.dispatch("updateEmployeeAttManageForm", request).then(() => {
        this.$emit("success", true);
        swal("Success!");
      });
    },
  },
  computed: {
    ...mapGetters({
      employee: "getAssignedDailyEmployeeAttendance",
      check: "checkUpdateEmpAttStatusEvent",
      attDate: "getDateEmployeeAttendance",
    }),
  },
  watch: {
    check(newState, oldState) {
      console.log("Component", newState, oldState);
      this.save();
    },
  },
};
</script>

<style scoped></style>
